<template>
  <v-container fluid>
    <v-row
      dense
      justify="space-between"
      align="center"
    >
      <v-col cols="auto">
        <v-btn
          v-if="userAccess.canCreate"
          color="primary"
          to="/main/term-of-payment/create"
        >
          {{$_strings.termOfPayment.buttonAdd}}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="5"
        md="3"
      >
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          @keyup.enter="search"
          :placeholder="$_strings.termOfPayment.searchText"
          v-model="searchText"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="search">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col>
        <v-data-table
          :loading="isLoading"
          :headers="displayedHeader"
          :items="items"
          item-key="id"
          :options.sync="pagination"
          @click:row="rowClicked"
          :server-items-length="totalItems"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
        >
          <template v-slot:[`item.paramValue`]={item}>
            <span>{{ item.top }}</span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  class="mr-2"
                  color="red"
                  @click="deleteTop(item)"
                  @click.stop.prevent
                  :loading="item.isLoading"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.DELETE}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.termOfPayment.pageName}}
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

export default {
  name: 'component-dokument',
  data() {
    return {
      isLoading: false,
      items: [],
      searchText: '',
      headers: [
        {
          text: this.$_strings.termOfPayment.headerNomor,
          value: 'paramValue',
        },
        {
          text: this.$_strings.termOfPayment.headerAksi,
          value: 'action',
        },
      ],
      pagination: this.defaultPagination(),
      totalItems: 0,
      itemStatus: [
        {
          key: this.$_strings.termOfPayment.statusAktif,
          value: true,
        },
        {
          key: this.$_strings.termOfPayment.statusNotAktif,
          value: false,
        },
      ],
    };
  },
  computed: {
    displayedHeader() {
      const { canDelete } = this.userAccess;
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      })).filter((header) => {
        if (!canDelete && header.value === 'action') return;
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchTop();
      },
      deep: true,
    },
  },
  methods: {
    skipEmptyStringObject,
    handleSortBy,
    handlerPagination,
    defaultPagination,
    rowClicked(e, { item }) {
      const { canUpdate } = this.userAccess;
      if (!canUpdate) return;
      this.$router.push(`/main/term-of-payment/edit/${item.id}`);
    },
    search() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
      } else {
        this.fetchTop();
      }
    },
    async fetchTop() {
      const {
        page,
        itemsPerPage,
        sortBy,
        sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: this.handleSortBy({ defaultValue: 'paramValue,ASC', sortBy, sortDesc }),
        paramValue: '',
      };
      if (this.searchText) filters.paramValue = `qLike(${this.searchText})`;
      try {
        this.isLoading = true;
        const res = await this.$_services.termOfPayment.topList(this.skipEmptyStringObject(filters));
        this.items = res.data.contents;
        this.totalItems = res.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    deleteTop(item) {
      this.$dialog.warning({
        text: 'Anda yakin akan menghapus?',
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then(async (userRes) => {
        if (!userRes) return;
        this.$set(item, 'isLoading', true);
        try {
          const topId = item.id;
          await this.$_services.termOfPayment.topDelete(topId);
          this.items.splice(this.items.indexOf(item), 1);
          this.$dialog.notify.success('Berhasil menghapus');
        } finally {
          this.$delete(item, 'isLoading');
        }
      });
    },
  },
};
</script>
