var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.userAccess.canCreate)?_c('v-btn',{attrs:{"color":"primary","to":"/main/term-of-payment/create"}},[_vm._v(" "+_vm._s(_vm.$_strings.termOfPayment.buttonAdd)+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"3"}},[_c('v-text-field',{staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":_vm.$_strings.termOfPayment.searchText},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":_vm.search}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),_c('br'),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"id","options":_vm.pagination,"server-items-length":_vm.totalItems,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.$_item_per_page,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        }},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.paramValue",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.top))])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","small":"","color":"red","loading":item.isLoading},on:{"click":[function($event){return _vm.deleteTop(item)},function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DELETE))])])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.termOfPayment.pageName)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }